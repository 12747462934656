import { useRef } from 'react';

import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { MembershipPaymentTermSelector } from 'src/app/shared/components/membershipPaymentTermSelector';

import type { Props } from '../../shared/types';
import { AmountSelector } from '../AmountSelector';

import { useMembershipAwarenessPlacementConfig } from './hooks/useMembershipAwarenessPlacementConfig';
import { useTracking } from './hooks/useTracking';

// eslint-disable-next-line max-lines-per-function
export function MembershipAwareness({ sourceLocation, slug, signed }: Props): React.JSX.Element {
	const element = useRef<HTMLElement>(null);
	const {
		data: {
			headlinePart1,
			headlinePart2,
			body,
			ctaQuestion,
			heroImage,
			paymentTerm,
			showOneTimePaymentTerm,
			showYearlyPaymentTerm,
		},
		actions: { onChange },
	} = useMembershipAwarenessPlacementConfig();

	useTracking({ sourceLocation, element });

	return (
		<Flex
			ref={element}
			id="membership-awareness"
			sx={{
				borderRadius: 'standard',
				flexDirection: 'column',
				backgroundColor: '#FAF7ED',
				width: 'fit-content',
				gap: 16,
				marginBottom: 24,
			}}
		>
			<Box
				sx={{
					textAlign: 'center',
					display: ['block', 'none'],
				}}
			>
				<Image alt="main-image" src={heroImage} />
			</Box>
			<Box sx={{ padding: [24] }}>
				<Box sx={{ marginBottom: [24] }}>
					<Heading size="h3" as="h2">
						<span sx={{ fontWeight: 'normal' }}>{headlinePart1} — &nbsp;</span>
						<Box sx={{ display: 'inline-block' }}>
							<span sx={{ position: 'relative', zIndex: 1 }}>{headlinePart2}</span>
							<Box
								sx={{
									backgroundColor: '#FFD4437A',
									maxWidth: '100%',
									height: '16px',
									position: 'relative',
									top: -10,
									borderRadius: 'standard',
								}}
							/>
						</Box>
					</Heading>
				</Box>

				<Flex sx={{ gap: '24px', flexWrap: 'wrap' }}>
					<Flex sx={{ flex: 1, minWidth: 250, flexDirection: 'column', gap: 16 }}>
						<Text>{body}</Text>
						<Text fontWeight="bold">{ctaQuestion}</Text>
					</Flex>
					<Box sx={{ flex: 1, minWidth: 250 }}>
						<MembershipPaymentTermSelector
							paymentTerm={paymentTerm}
							onChange={onChange}
							name="payment-terms-awareness"
							showYearlyPaymentTerm={showYearlyPaymentTerm}
							showOneTimePaymentTerm={showOneTimePaymentTerm}
						/>
						<AmountSelector sourceLocation={sourceLocation} slug={slug} signed={signed} />
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}
