/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useNavigate } from '@change-corgi/core/react/router';
import { useTracking } from '@change-corgi/core/react/tracking';

import { useYearlyMembershipExperimentAmounts } from 'src/app/shared/hooks/membership';

import {
	useMembershipAwarenessFormData,
	useMembershipAwarenessFormFcm,
	useMembershipAwarenessFormFcmExperiments,
	useMembershipAwarenessFormState,
} from '../../../../formContext';
import type { Props } from '../../../../shared/types';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText?: string;
		displayPayWithText: boolean;
	},
	{
		onClick: (amount?: number) => void;
	}
>;

export function useAmountSelector({ sourceLocation, slug, signed }: Props): Result {
	const track = useTracking();
	const navigate = useNavigate();
	const { amountToBaseUnits, translate } = useI18n();
	const {
		subscriptionsAmounts: buttonAmounts,
		subscriptionsDefaultAmount: defaultAmount,
		currency,
	} = useMembershipAwarenessFormData();
	const { subscriptionConfig, yearlyContributionsAmounts } = useMembershipAwarenessFormFcm();
	const { showYearlyContributions } = useMembershipAwarenessFormFcmExperiments();
	const [{ paymentTerm }] = useMembershipAwarenessFormState();

	const yearlyContributionsExperimentAmounts = useYearlyMembershipExperimentAmounts();

	const yearlyContributionsExperimentAmountsResult = useMemo(() => {
		if (showYearlyContributions.variation !== 'control') {
			return yearlyContributionsExperimentAmounts({
				yearlyContributionsExpVariation: showYearlyContributions.variation,
				yearlyContributionsAmounts,
				paymentTerm,
				buttonAmounts,
				currency,
			});
		}
		return buttonAmounts;
	}, [
		buttonAmounts,
		currency,
		paymentTerm,
		showYearlyContributions.variation,
		yearlyContributionsAmounts,
		yearlyContributionsExperimentAmounts,
	]);

	const buttonAmountOptions = useMemo(() => {
		return yearlyContributionsExperimentAmountsResult;
	}, [yearlyContributionsExperimentAmountsResult]);

	const selectedAmount = useMemo(() => {
		if (showYearlyContributions.variation !== 'control') {
			return yearlyContributionsExperimentAmountsResult[1];
		}
		return defaultAmount;
	}, [showYearlyContributions.variation, defaultAmount, yearlyContributionsExperimentAmountsResult]);

	const onClick = useCallback(
		(amount?: number) => {
			void track(`${sourceLocation}_membership_ask_click`);
			if (subscriptionConfig?.thirdPartySubscriptionService) {
				void track('membership_ask_click_third_party_subscription_service');
				navigate(subscriptionConfig?.thirdPartySubscriptionService);
				return;
			}
			const path = slug ? `/p/${encodeURIComponent(slug)}/psf/membership` : '/member/join';
			navigate(
				`${path}?source_location=${sourceLocation}&amount=${amountToBaseUnits(amount ?? selectedAmount.amount, currency)}&payment_term=${paymentTerm}`,
				{ state: { signed, slug } },
			);
		},
		[
			track,
			sourceLocation,
			subscriptionConfig?.thirdPartySubscriptionService,
			slug,
			signed,
			navigate,
			amountToBaseUnits,
			selectedAmount.amount,
			currency,
			paymentTerm,
		],
	);

	return {
		data: {
			selectedAmount,
			buttonAmounts: buttonAmountOptions,
			buttonText: translate('corgi.membership.awareness.cta'),
			displayPayWithText: !subscriptionConfig?.thirdPartySubscriptionService,
		},
		actions: {
			onClick,
		},
	};
}
