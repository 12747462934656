import { withPrefetchedData } from 'src/shared/prefetch';

import { MembershipAwarenessContainer } from './container';
import type { MembershipAwarenessFormPrefetchedDataProps } from './formContext';
import { MembershipAwarenessFormContextProvider, prefetchFormContext } from './formContext';
import type { Props } from './shared/types';

type MembershipAwarenessProps = MembershipAwarenessFormPrefetchedDataProps & Props;

export const MembershipAwareness = withPrefetchedData(
	function MembershipAwarenessEntry({
		prefetchedData,
		sourceLocation,
		petitionId,
		slug,
		signed,
	}: MembershipAwarenessProps): React.JSX.Element {
		return (
			<MembershipAwarenessFormContextProvider prefetchedData={prefetchedData} petitionId={petitionId}>
				<MembershipAwarenessContainer sourceLocation={sourceLocation} slug={slug} signed={signed} />
			</MembershipAwarenessFormContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => prefetchFormContext(context),
	},
);
