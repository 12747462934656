import type { PrefetchContext, PrefetchedDataProps } from 'src/shared/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchMembershipAwarenessFormDataFromApi, useMembershipAwarenessFormDataFromApi } from './formData';
import type { MembershipAwarenessFormDataPrefetchedState, MembershipAwarenessFormDataState } from './formData';
import type { MembershipAwarenessFormFcmState } from './formFcm';
import { prefetchMembershipAwarenessFormFcm, useAsyncMembershipAwarenessFormFcm } from './formFcm';
import type { MembershipAwarenessFormFcmExperimentsState } from './formFcmExperiments';
import {
	prefetchMembershipAwarenessFormFcmExperiments,
	useAsyncMembershipAwarenessFormFcmExperiments,
} from './formFcmExperiments';

export type MembershipAwarenessFormPrefetchedContext = Readonly<{
	formData: MembershipAwarenessFormDataPrefetchedState;
}>;

export type MembershipAwarenessFormCombinedState = MergedState<
	MembershipAwarenessFormDataState,
	MembershipAwarenessFormFcmState,
	MembershipAwarenessFormFcmExperimentsState
>;

export function useFormContext(
	prefetchedData?: MembershipAwarenessFormPrefetchedContext,
): MembershipAwarenessFormCombinedState {
	return useMergedStates(
		useMembershipAwarenessFormDataFromApi(prefetchedData?.formData),
		useAsyncMembershipAwarenessFormFcm(),
		useAsyncMembershipAwarenessFormFcmExperiments(),
	);
}

export async function prefetchFormContext(context: PrefetchContext): Promise<MembershipAwarenessFormPrefetchedContext> {
	const { session } = context;

	if (!session) throw new Error('Unexpected missing session');
	const [formData] = await Promise.all([
		prefetchMembershipAwarenessFormDataFromApi(context),
		prefetchMembershipAwarenessFormFcm(context),
		prefetchMembershipAwarenessFormFcmExperiments(context),
	]);

	return { formData };
}

export type MembershipAwarenessFormPrefetchedDataProps = PrefetchedDataProps<MembershipAwarenessFormPrefetchedContext>;
